@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,700&display=swap');

body {
  margin: 0 auto;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  min-height: 100vh;
}

@layer base {

  ul,
  ol {
    list-style: revert;
  }
}

* {
  scroll-behavior: smooth;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

.messageScrollBar::-webkit-scrollbar {
  height: 0px;
}

.messageScrollBar:hover::-webkit-scrollbar {
  height: 0.1em;
}

*::-webkit-scrollbar {
  width: .5em;
}

@media screen and (max-width:600px) {
  *::-webkit-scrollbar {
    width: .2em;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(248, 248, 248, 0.521);
  outline: 1px solid rgba(211, 211, 211, 0.726);
  border-radius: 1rem;
}

/* *:focus {
  background-color: transparent;
} */

/* Caller scroller hidden */
.callerScroll::-webkit-scrollbar,
.callBoardScroll::-webkit-scrollbar {
  display: none;
}

.commonInput:not(:placeholder-shown):focus:invalid {
  box-shadow: 0 0 5px #d45252;
  border-color: #b03535;
}

.emojiParent:hover>.emojiChild {
  visibility: visible;
  opacity: 100;
}


#drawerElapsedtime:checked~span {
  display: inline-block;
}

.rotate180 {
  transform: rotate(90deg);
}

/* Scrollbar of tasks */

/* Hide scrollbar for Chrome, Safari and Opera */
.no_taskScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no_taskScrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  left: -9999px;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}