.custom-snackbar {
  color: white;
  border-radius: 4px;
  width: 22rem;
  cursor: pointer;
  position: relative;
  height: auto;
  margin: 0.75rem 0;
}


.alert-content {
  white-space: normal;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
}